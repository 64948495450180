/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { renderingSanitizer as e } from "../../widgets/support/widgetUtils.js";
const t = "http://www.w3.org/",
  r = `${t}2000/svg`,
  o = `${t}1999/xlink`;
let i = [],
  n = (e, t) => {
    let r = {};
    return Object.keys(e).forEach(t => {
      r[t] = e[t];
    }), t && Object.keys(t).forEach(e => {
      r[e] = t[e];
    }), r;
  },
  s = (e, t) => e.vnodeSelector === t.vnodeSelector && (e.properties && t.properties ? e.properties.key === t.properties.key && e.properties.bind === t.properties.bind : !e.properties && !t.properties),
  l = e => {
    if ("string" != typeof e) throw new Error("Style values must be strings");
  },
  p = (e, t, r) => {
    if ("" !== t.vnodeSelector) for (let o = r; o < e.length; o++) if (s(e[o], t)) return o;
    return -1;
  },
  d = (e, t, r, o) => {
    let i = e[t];
    if ("" === i.vnodeSelector) return;
    let n = i.properties;
    if (!(n ? void 0 === n.key ? n.bind : n.key : void 0)) for (let l = 0; l < e.length; l++) if (l !== t) {
      let t = e[l];
      if (s(t, i)) throw new Error(`${r.vnodeSelector} had a ${i.vnodeSelector} child ${"added" === o ? o : "removed"}, but there is now more than one. You must add unique key properties to make them distinguishable.`);
    }
  },
  a = e => {
    if (e.properties) {
      let t = e.properties.enterAnimation;
      t && t(e.domNode, e.properties);
    }
  },
  f = [],
  c = !1,
  u = e => {
    (e.children || []).forEach(u), e.properties && e.properties.afterRemoved && e.properties.afterRemoved.apply(e.properties.bind || e.properties, [e.domNode]);
  },
  m = () => {
    c = !1, f.forEach(u), f.length = 0;
  },
  h = e => {
    f.push(e), c || (c = !0, "undefined" != typeof window && "requestIdleCallback" in window ? window.requestIdleCallback(m, {
      timeout: 16
    }) : setTimeout(m, 16));
  },
  v = e => {
    let t = e.domNode;
    if (e.properties) {
      let r = e.properties.exitAnimation;
      if (r) {
        t.style.pointerEvents = "none";
        let o = () => {
          t.parentNode && (t.parentNode.removeChild(t), h(e));
        };
        return void r(t, o, e.properties);
      }
    }
    t.parentNode && (t.parentNode.removeChild(t), h(e));
  },
  y = (t, i, n) => {
    if (!i) return;
    let s = n.eventHandlerInterceptor,
      p = Object.keys(i),
      d = p.length;
    for (let a = 0; a < d; a++) {
      let d = p[a],
        f = i[d];
      if ("className" === d) throw new Error('Property "className" is not supported, use "class".');
      if ("class" === d) k(t, f, !0);else if ("classes" === d) {
        let e = Object.keys(f),
          r = e.length;
        for (let o = 0; o < r; o++) {
          let r = e[o];
          f[r] && t.classList.add(r);
        }
      } else if ("styles" === d) {
        let e = Object.keys(f),
          r = e.length;
        for (let o = 0; o < r; o++) {
          let r = e[o],
            i = f[r];
          i && (l(i), n.styleApplyer(t, r, i));
        }
      } else if ("key" !== d && null != f) {
        let l = typeof f;
        "function" === l ? (0 === d.lastIndexOf("on", 0) && (s && (f = s(d, f, t, i)), "oninput" === d && function () {
          let e = f;
          f = function (t) {
            e.apply(this, [t]), t.target["oninput-value"] = t.target.value;
          };
        }()), t[d] = f) : n.namespace === r ? "href" === d ? t.setAttributeNS(o, d, f) : t.setAttribute(d, f) : "string" === l && "value" !== d ? "innerHTML" === d ? t[d] = e.sanitize(f) : g(t) && d in t ? t[d] = f : t.setAttribute(d, f) : t[d] = f;
      }
    }
  };
function g(e) {
  if (!(e instanceof Element && e.tagName.includes("-"))) return !1;
  const t = window.customElements.get(e.tagName.toLowerCase());
  return !!t && e instanceof t;
}
let N,
  b = (e, t, r) => {
    if (t) for (let o of t) x(o, e, void 0, r);
  },
  w = (e, t, r) => {
    b(e, t.children, r), t.text && (e.textContent = t.text), y(e, t.properties, r), t.properties && t.properties.afterCreate && t.properties.afterCreate.apply(t.properties.bind || t.properties, [e, r, t.vnodeSelector, t.properties, t.children]);
  },
  x = (e, t, o, i) => {
    let s,
      l = 0,
      p = e.vnodeSelector,
      d = t.ownerDocument;
    if ("" === p) s = e.domNode = d.createTextNode(e.text), void 0 !== o ? t.insertBefore(s, o) : t.appendChild(s);else {
      for (let a = 0; a <= p.length; ++a) {
        let f = p.charAt(a);
        if (a === p.length || "." === f || "#" === f) {
          let f = p.charAt(l - 1),
            c = p.slice(l, a);
          "." === f ? s.classList.add(c) : "#" === f ? s.id = c : ("svg" === c && (i = n(i, {
            namespace: r
          })), void 0 !== i.namespace ? s = e.domNode = d.createElementNS(i.namespace, c) : (s = e.domNode = e.domNode || d.createElement(c), "input" === c && e.properties && void 0 !== e.properties.type && s.setAttribute("type", e.properties.type)), void 0 !== o ? t.insertBefore(s, o) : s.parentNode !== t && t.appendChild(s)), l = a + 1;
        }
      }
      w(s, e, i);
    }
  },
  k = (e, t, r) => {
    t && t.split(" ").forEach(t => {
      t && e.classList.toggle(t, r);
    });
  },
  A = (t, i, n, s) => {
    if (!n) return;
    let p = !1,
      d = Object.keys(n),
      a = d.length;
    for (let f = 0; f < a; f++) {
      let a = d[f],
        c = n[a],
        u = i[a];
      if ("class" === a) u !== c && (k(t, u, !1), k(t, c, !0));else if ("classes" === a) {
        let e = t.classList,
          r = Object.keys(c),
          o = r.length;
        for (let t = 0; t < o; t++) {
          let o = r[t],
            i = !!c[o];
          i !== !!u[o] && (p = !0, i ? e.add(o) : e.remove(o));
        }
      } else if ("styles" === a) {
        let e = Object.keys(c),
          r = e.length;
        for (let o = 0; o < r; o++) {
          let r = e[o],
            i = c[r];
          i !== u[r] && (p = !0, i ? (l(i), s.styleApplyer(t, r, i)) : s.styleApplyer(t, r, ""));
        }
      } else if (c || "string" != typeof u || (c = ""), "value" !== a || g(t)) {
        if (c !== u) {
          let i = typeof c;
          "function" === i && s.eventHandlerInterceptor || (s.namespace === r ? "href" === a ? t.setAttributeNS(o, a, c) : t.setAttribute(a, c) : "string" === i ? "innerHTML" === a ? t[a] = e.sanitize(c) : "role" === a && "" === c ? t.removeAttribute(a) : g(t) && a in t ? t[a] = c : t.setAttribute(a, c) : t[a] !== c && (t[a] = c), p = !0);
        }
      } else {
        let e = t[a];
        e !== c && (t["oninput-value"] ? e === t["oninput-value"] : c !== u) && (t[a] = c, t["oninput-value"] = void 0), c !== u && (p = !0);
      }
    }
    return p;
  },
  S = (e, t, r, o, n) => {
    if (r === o) return !1;
    o = o || i;
    let l,
      f = (r = r || i).length,
      c = o.length,
      u = 0,
      m = 0,
      h = !1;
    for (; m < c;) {
      let i = u < f ? r[u] : void 0,
        c = o[m];
      if (void 0 !== i && s(i, c)) h = N(i, c, n) || h, u++;else {
        let i = p(r, c, u + 1);
        if (i >= 0) {
          for (l = u; l < i; l++) v(r[l]), d(r, l, e, "removed");
          h = N(r[i], c, n) || h, u = i + 1;
        } else x(c, t, u < f ? r[u].domNode : void 0, n), a(c), d(o, m, e, "added");
      }
      m++;
    }
    if (f > u) for (l = u; l < f; l++) v(r[l]), d(r, l, e, "removed");
    return h;
  };
N = (e, t, o) => {
  let i = e.domNode,
    s = !1;
  if (e === t) return !1;
  let l = !1;
  if ("" === t.vnodeSelector) {
    if (t.text !== e.text) {
      let e = i.ownerDocument.createTextNode(t.text);
      return i.parentNode.replaceChild(e, i), t.domNode = e, s = !0, s;
    }
    t.domNode = i;
  } else 0 === t.vnodeSelector.lastIndexOf("svg", 0) && (o = n(o, {
    namespace: r
  })), e.text !== t.text && (l = !0, void 0 === t.text ? i.removeChild(i.firstChild) : i.textContent = t.text), t.domNode = i, l = S(t, i, e.children, t.children, o) || l, l = A(i, e.properties, t.properties, o) || l, t.properties && t.properties.afterUpdate && t.properties.afterUpdate.apply(t.properties.bind || t.properties, [i, o, t.vnodeSelector, t.properties, t.children]);
  return l && t.properties && t.properties.updateAnimation && t.properties.updateAnimation(i, t.properties, e.properties), s;
};
let C = (e, t) => ({
  getLastRender: () => e,
  update: r => {
    if (e.vnodeSelector !== r.vnodeSelector) throw new Error("The selector for the root VNode may not be changed. (consider using dom.merge and add one extra level to the virtual DOM)");
    let o = e;
    e = r, N(o, r, t);
  },
  domNode: e.domNode
});
export { x as createDom, C as createProjection, n as extend, w as initPropertiesAndChildren };