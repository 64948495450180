/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { isWidgetConstructor as r } from "./jsxWidgetSupport.js";
var e = function (r) {
    return {
      vnodeSelector: "",
      properties: void 0,
      children: void 0,
      text: r.toString(),
      domNode: null
    };
  },
  o = function (r, t) {
    for (var n = 0, i = r.length; n < i; n++) {
      var d = r[n];
      Array.isArray(d) ? o(d, t) : null != d && !1 !== d && (d.hasOwnProperty("vnodeSelector") || (d = e(d)), t.push(d));
    }
  },
  t = function (r, e) {
    for (var t = [], n = 2; n < arguments.length; n++) t[n - 2] = arguments[n];
    if (1 === t.length && "string" == typeof t[0]) return {
      vnodeSelector: r,
      properties: e || void 0,
      children: void 0,
      text: t[0],
      domNode: null
    };
    var i = [];
    return o(t, i), {
      vnodeSelector: r,
      properties: e || void 0,
      children: i,
      text: void 0,
      domNode: null
    };
  };
function n(e, o, ...n) {
  return "function" != typeof e || r(e) ? t(e, o, ...n) : e(o, ...n);
}
function i(...r) {
  return r;
}
export { n as tsx, i as tsxFragment };